import Location from '../../../types/Location'
import { HomePageViewModel } from './HomePageViewModel'

import { getLocations } from '../../../api/locationsApi'
import config from '../../../config'

export const buildViewModel = async () => {
  const locations: Location[] | undefined = await getLocations('all')
  const homepageData = require('./homepage-data.json')

  const popularCitiesFromLocations =
    locations?.filter((location) =>
      location.possibleNames.some((name) =>
        homepageData.featuredCities
          .map((city: { name: string; imageUrl: string }) => city.name)
          .includes(name)
      )
    ) || []

  const featuredCities = homepageData.featuredCities.map(
    (featuredCity: { name: string; imageUrl: string }) => {
      const cityWithPropertyCount = popularCitiesFromLocations.find(
        (location) => location.name === featuredCity.name
      )

      return {
        ...featuredCity,
        ...cityWithPropertyCount,
      }
    }
  )

  const viewModel: HomePageViewModel = {
    locations,
    ...homepageData,
    featuredCities,
    absoluteUrl: config.baseUrl,
  }

  return viewModel
}
