import React from 'react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { getOrAdd } from '../services/cacheService';
import { buildViewModel } from '../components/pages/HomePage/viewModelBuilder';
import { withCountryServer } from '../contexts/countryContext';
import { getAutocompleteSuggestions } from '../api/autocompleteApi';
import { getCoordinatesFromGooglePlaceId } from '../api/geoCoordinatesApi';
import HomePage, { HomePageProps } from '../components/pages/HomePage';
export const homePageNewGetServerSideProps: GetServerSideProps = async ({
  req
}) => {
  const {
    headers
  } = req;
  const userAgent = headers['user-agent'] || '';
  const deviceSelectors = getSelectorsByUserAgent(userAgent) || {};
  const {
    isMobileOnly = false
  } = deviceSelectors;
  const viewModel = await getOrAdd('home:gb', async () => await buildViewModel());
  return {
    props: {
      viewModel,
      initialIsMobileDevice: isMobileOnly
    }
  };
};
export const getServerSideProps = withCountryServer(homePageNewGetServerSideProps);
const Home = ({
  viewModel,
  initialIsMobileDevice
}: HomePageProps) => {
  return <HomePage viewModel={viewModel} initialIsMobileDevice={initialIsMobileDevice} history={useRouter()} autocompleteApi={getAutocompleteSuggestions} geoCoordinatesApi={getCoordinatesFromGooglePlaceId} data-sentry-element="HomePage" data-sentry-component="Home" data-sentry-source-file="index.tsx" />;
};
export default Home;