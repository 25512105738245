import React from 'react';
import Link from '../../../atoms/Link';
import styles from './styles.module.scss';
const Faqs = [{
  question: 'How does Accommodation for Students work?',
  answer: 'Search by city or university to find the latest student accommodation. We feature student properties advertised by landlords, letting agents and private hall providers. It is free to use our service and you can enquire or book directly to the properties that are advertised.'
}, {
  question: 'Who are Accommodation for Students?',
  answer: 'Accommodation for Students has been the go-to website for student accommodation since 2000, offering a wide range of housing options for university students. We know how vital it is to have a nice place to chill after lectures (or a night out with friends!).'
}, {
  question: 'What type of accommodation does Accommodation for Students have?',
  answer: "We feature a wide range of student accommodation. Whether you're looking for a cosy house, a modern flat, or the top halls in your uni town, our search engine can help you find exactly what you need. All of the accommodation featured on our website has been verified by our team so you can be confident that each advertisement is genuine."
}, {
  question: 'How do you differ from other websites?',
  answer: 'Accommodation for Students puts the power in your hands. You can connect directly with the accommodation featured on our website to make your own decision about which is the most suitable for you. By dealing directly with the accommodation provider you can make sure you get all of the information you need before deciding to book.'
}, {
  question: 'Can I advertise a spare room or tenancy takeover?',
  answer: <>
        You can list your spare room on{' '}
        <Link className={styles.link} href="https://www.studenthousemates.com" target="_blank">
          Student Housemates
        </Link>
        . Our dedicated free-to-use website. Designed to help students find and
        promote spare rooms in student houses, flats, and halls.
      </>
}];
export default Faqs;