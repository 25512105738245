import React from 'react';
import classNames from 'classnames';
import Properties from './svgs/properties.svg';
import Bookings from './svgs/bookings.svg';
import Enquiries from './svgs/enquiries.svg';
import Star from './svgs/star.svg';
import styles from './styles.module.scss';
export interface UspSectionWithAdditionalPointProps {
  className?: string;
}
const UspSectionWithAdditionalPoint = ({
  className
}: UspSectionWithAdditionalPointProps) => {
  return <section className={classNames(styles.outer, className)} data-sentry-component="UspSectionWithAdditionalPoint" data-sentry-source-file="index.tsx">
      <ul className={styles.inner}>
        <li className={styles.uspWrapper}>
          <Star className={styles.icon} data-sentry-element="Star" data-sentry-source-file="index.tsx" />
          <div className={styles.text}>
            <span className={styles.heading}>Trusted by 1m+ students</span>
            <p className={styles.subHeading}>
              Every year, we help over a million students find their ideal place
            </p>
          </div>
        </li>

        <li className={styles.uspWrapper}>
          <Bookings className={styles.icon} data-sentry-element="Bookings" data-sentry-source-file="index.tsx" />
          <div className={styles.text}>
            <span className={styles.heading}>Quick & easy bookings</span>
            <p className={styles.subHeading}>
              Secure your room in no time with hassle-free instant booking
            </p>
          </div>
        </li>

        <li className={styles.uspWrapper}>
          <Properties className={styles.icon} data-sentry-element="Properties" data-sentry-source-file="index.tsx" />
          <div className={styles.text}>
            <span className={styles.heading}>The widest choice</span>
            <p className={styles.subHeading}>
              Browse verified, affordable student rooms close to university
            </p>
          </div>
        </li>

        <li className={styles.uspWrapper}>
          <Enquiries className={styles.icon} data-sentry-element="Enquiries" data-sentry-source-file="index.tsx" />
          <div className={styles.text}>
            <span className={styles.heading}>We’re here to help</span>
            <p className={styles.subHeading}>
              Reach out to our friendly team of experts who are always on hand
            </p>
          </div>
        </li>
      </ul>
    </section>;
};
export default UspSectionWithAdditionalPoint;