import React from 'react';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import config from '../../../config';
import Picture from '../../atoms/Picture';
import Button from '../../atoms/Button';
import Arrow from './arrow.svg';
import styles from './styles.module.scss';
export interface AdvertiseSectionProps {
  className?: string;
}
const AdvertiseSection = ({
  className
}: AdvertiseSectionProps) => {
  const advertiseImage = 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/advertise.png';
  return <section className={classNames(styles.outer, className)} data-sentry-component="AdvertiseSection" data-sentry-source-file="index.tsx">
      <div className={styles.leftColumn}>
        <strong className={styles.subheading}>Accommodation providers</strong>
        <Heading level={3} className={styles.heading} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          Advertise your properties with confidence
        </Heading>
        <div className={styles.description}>
          Landlords, letting agents, and private hall managers – advertise your
          property in minutes and reach thousands of students daily. Get
          unparalleled exposure and attract the ideal tenants for your spaces!
        </div>
        <Button className={styles.linkButton} to="/advertise" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          Advertise your property <Arrow className={styles.linkArrow} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
        </Button>
      </div>

      <div className={styles.rightColumn}>
        <div className={styles.advertiseImageWrapper}>
          <Picture className={styles.advertiseImage} lazyload src={`${config.imgApiUrl}w=520/${advertiseImage}`} sources={[{
          id: 'desktop',
          srcSet: `${config.imgApiUrl}w=520/${advertiseImage} 1x, ${config.imgApiUrl}w=520,dpr=2/${advertiseImage} 2x`,
          media: '(min-width: 481px)'
        }, {
          id: 'mobile',
          srcSet: `${config.imgApiUrl}w=448/${advertiseImage} 1x, ${config.imgApiUrl}w=448,dpr=2/${advertiseImage} 2x`,
          media: '(max-width: 480px)'
        }]} alt="Example of an advertised property card with a smiling man in the foreground" data-sentry-element="Picture" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </section>;
};
export default AdvertiseSection;